import { FeatureCollection, Geometry } from "geojson";
import { cloneDeep } from "lodash";

const single_org_metadata = [
  "list_name",
  "itemname",
  "material",
  "quantity",
  "description",
  "latitude",
  "longitude",
  "altitude",
  "radius",
  "location",
  "timestamp",
  "dt",
  "project_name",
  "username",
  "manual_upload",
  "event_name",
  "id",
  "log_index"
];

const all_org_metadata = [
  "list_name",
  "master_item_name",
  "master_material",
  "itemname",
  "material",
  "quantity",
  "description",
  "latitude",
  "longitude",
  "altitude",
  "radius",
  "location",
  "timestamp",
  "dt",
  "project_name",
  "username",
  "manual_upload",
  "event_name",
  "id",
  "log_index"
];

export const formatDownloadGeojson = (
  geojson: FeatureCollection<Geometry, { [name: string]: any }>,
  allOrg: boolean
) => {
  const comparatorList = allOrg ? all_org_metadata : single_org_metadata;
  const geojsonCopy = cloneDeep(geojson);
  // ensure data properties match metadata doc
  geojsonCopy.features.forEach((feature) => {
    Object.keys(feature.properties).forEach((keyname) => {
      if (comparatorList.includes(keyname)) return;
      delete feature.properties[keyname];
    });
  });
  return geojsonCopy;
};
