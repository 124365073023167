import React, { useEffect, useState } from "react";
import { BarChartType, PieChartDataType } from "../../types/charts";
import { geojsonToBar, geojsonToPie } from "../../util/data/chartFunctions";
import MapStatsContainer from "./Map/MapStatsContainer";

const MapStats = ({
  geojson,
  loading,
}: {
  geojson: GeoJSON.FeatureCollection;
  loading: boolean;
}) => {
  const [itemCount, setItemCount] = useState<number>(0);
  const [eventCount, setEventCount] = useState<number>(0);
  const [pieData, setPieData] = useState<PieChartDataType>([]);
  const [barData, setBarData] = useState<BarChartType>([]);

  // calculate new counts
  useEffect(() => {
    if (!geojson) {
      return;
    }
    let newItemCount = 0;
    let newEventCount = 0;
    geojson.features.forEach((item) => {
      newItemCount += parseInt(item.properties.quantity, 10);
      newEventCount += 1;
    });
    setItemCount(newItemCount);
    setEventCount(newEventCount);
  }, [geojson]);

  // new pie data
  useEffect(() => {
    const newPieData = geojsonToPie(geojson);
    setPieData(newPieData);
  }, [geojson]);

  // new bar data
  useEffect(() => {
    const data = geojsonToBar(geojson);
    setBarData(data);
  }, [geojson]);

  return (
    <>
      <MapStatsContainer
        loading={loading}
        itemCount={itemCount}
        eventCount={eventCount}
        pieData={pieData}
        barData={barData}
      />
    </>
  );
};

export default MapStats;
