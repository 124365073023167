import React, { useContext, useRef, useState } from "react";
import { DataPlatformContext } from "../../context/DataPlatform/DataPlatformContext";
import Button from "../Button/Button";
import Switch from "../Forms/Switch";
import {
  FiltersContainer,
  FiltersTitle,
  FiltersWrapper,
} from "./Filters/FiltersComponents";
import ListSelect from "./Filters/ListSelect";
import CategoriesFilter from "./Filters/CategoriesFilter";
import RadioGroupFilter from "./Filters/RadioGroupFilter";
import { FilterType } from "../../types/filters";
import styled from "styled-components";
import { colors } from "../../styles/colors";
import dayjs from "dayjs";
import GeoJSONtoCSV from "../../util/data/GeoJSONtoCSV";
import { formatDownloadGeojson } from "../../util/data/formatDownloadData";
import { JSONtoCSV } from "../../util/data/JSONtoCSV";
import { DebrisTrackerContext } from "../../context/DebrisTrackerContext";

const FilterErrorMessage = styled.p`
  color: ${colors.orange};
  text-align: center;
  margin-bottom: 0px;
`;

const Filters = ({ geojson }: { geojson: GeoJSON.FeatureCollection }) => {
  const { filters, setFilters, loading, loadData, setData } = useContext(
    DataPlatformContext
  );

  const { user } = useContext(DebrisTrackerContext);

  const linkRef = useRef<any>(undefined);

  const [err, setErr] = useState<string>("");

  const setErrMessage = (str: string) => {
    setErr(str);
    setTimeout(() => {
      setErr("");
    }, 2000);
  };

  const filtersRef = useRef<FilterType>(filters);

  const shouldFetch = () => {
    if (
      filters.date_type === "range" &&
      dayjs(filters.start_date).isAfter(filters.end_date)
    ) {
      setErrMessage("Invalid Date Range");
      return false;
    }

    if (
      filters.list !== filtersRef.current.list ||
      filters.date_type !== filtersRef.current.date_type ||
      (filters.date_type === "range" &&
        filters.start_date !== filtersRef.current.start_date) ||
      (filters.date_type === "range" &&
        filters.end_date !== filtersRef.current.end_date) ||
      (filters.date_type === "exact" &&
        filters.exact_date !== filtersRef.current.exact_date)
    ) {
      return true;
    }
    return false;
  };

  const searchData = async () => {
    if (shouldFetch()) {
      const res = await loadData(filters);
      setData(res);
      filtersRef.current = filters;
    } else {
      filtersRef.current = filters;
    }
  };

  const DownloadButton = () => {
    const ButtonWrapper = styled.div`
      margin-top: 10px;
      text-align: center;
      padding: 15px;
      cursor: pointer;
    `;

    const handleDownload = () => {
      // compute data and convert to csv
      const newDownloadData = GeoJSONtoCSV(
        formatDownloadGeojson(geojson, filters.list === "all")
      );
      const csvData = JSONtoCSV(newDownloadData);

      // store data in new blob and create a url
      const csvBlob = new Blob([csvData], { type: "text/csv" });
      const url = URL.createObjectURL(csvBlob);

      // set <a> tags href to blob url
      if (linkRef.current) {
        linkRef.current.href = url;
      }
      // click the tag
      linkRef.current && linkRef.current.click();
    };

    return (
      <>
        <ButtonWrapper onClick={handleDownload}>
          Download Selected Data
        </ButtonWrapper>
        <a target="_blank" download={"mdt-data.csv"} ref={linkRef}></a>
      </>
    );
  };

  return (
    <FiltersContainer>
      <FiltersTitle>Filter</FiltersTitle>
      {err && <FilterErrorMessage>{err}</FilterErrorMessage>}
      <FiltersWrapper>
        {filters.list !== "all" && user && user.id && (
          <Switch
            handleChange={() => {
              if (filters.user_data == -1) {
                setFilters({ ...filters, user_data: user.id });
              } else {
                setFilters({ ...filters, user_data: -1 });
              }
            }}
            checked={filters.user_data !== -1}
          >
            My Data
          </Switch>
        )}
        {filters.list !== "all" && (
          <Switch
            handleChange={() => {
              setFilters({ ...filters, showManual: !filters.showManual });
            }}
            checked={filters.showManual}
          >
            Show manual events
          </Switch>
        )}
        <ListSelect />
        <CategoriesFilter />
        <RadioGroupFilter />
        <Button
          style={{
            width: "100%",
            marginTop: "10px",
            padding: "10px 0px",
            opacity: filters.list ? "1" : "0.75",
            cursor: filters.list ? "pointer" : "default",
          }}
          onClick={filters.list && !loading ? searchData : null}
        >
          Search
        </Button>
        {geojson && geojson.features.length > 0 && <DownloadButton />}
      </FiltersWrapper>
    </FiltersContainer>
  );
};

export default Filters;
