import React from "react";
import styled from "styled-components";
import { colors } from "../../styles/colors";

const SwitchLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 40px;
  height: 13px;
  background: ${({ isChecked }) =>
    isChecked ? `${colors.orange}65` : `${colors.black}65`};
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
`;

const SwitchButton = styled.span`
  position: absolute;
  /* top: 2px; */
  left: 2px;
  width: 23px;
  height: 23px;
  border-radius: 45px;
  transition: 0.3s;
  background: ${({ isChecked }) => (isChecked ? colors.orange : colors.white)};
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  left: ${({ isChecked }) => (isChecked ? `calc(100% - 2px)` : `0px`)};
  transform: ${({ isChecked }) =>
    isChecked ? `translateX(-90%)` : `translateX(0%)`};
`;

const SwitchWrapper = styled.div`
  display: flex;
  margin: 5px 0px;
  align-items: center;
`;

const SwitchContent = styled.div`
  margin-left: 10px;
`;

const Switch = ({ handleChange, checked, children }) => {
  return (
    <>
      <SwitchWrapper>
        <SwitchLabel onClick={handleChange} isChecked={checked}>
          <SwitchButton isChecked={checked} />
        </SwitchLabel>
        <SwitchContent>{children}</SwitchContent>
      </SwitchWrapper>
    </>
  );
};

export default Switch;
