import React from "react";
import styled from "styled-components";
import { colors } from "../../../styles/colors";
import numberWithCommas from "../../../util/numberWithCommas";
import BarChart from "../Charts/BarChart";
import PieChart from "../Charts/PieChart";
// import PieChart from "../../components/Dashboard/PieChart";
// import BarChart from "../../components/Dashboard/BarChart";

const MapStatsWrapper = styled.div`
  font-family: "Lato";
  color: #536275;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  @media only screen and (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

const MapStatsTitle = styled.h2`
  font-weight: 400;
  font-size: 22px;
  margin-bottom: 0px;
  margin-top: -50px;
  width: auto;
  background-color: white;
  z-index: 1200;
  padding: 19px;
  margin-left: -20px;
  width: 300px;

  @media only screen and (max-width: 991px) {
    margin: 0px;
    padding-left: 0px;
  }
  @media only screen and (max-width: 478px) {
    width: auto;
  }
`;

const MapStatsTotals = styled.div`
  display: flex;
  flex-direction: column;
`;

const TotalLabel = styled.span`
  font-weight: 700;
  margin: 10px 0px;
`;

const Total = styled.span`
  color: ${colors.orange};
`;

const MapStatsGraphs = styled.div`
  display: flex;
  flex-direction: row;
`;

const Chart = styled.div`
  margin-left: ${({ left }) => (left ? "20px" : "0px")};

  @media only screen and (max-width: 768px) {
    margin-left: 0px;
  }
`;

const Charts = styled.div`
  display: flex;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const ChartWrapper = styled.div`
  min-width: 30vw;
`;

const TooManyItems = () => {
  return (
    <div>
      <h2>Too many items in result set</h2>
      <TotalLabel>
        Our platform can't load this much data, try narrowing the date range and try again.
      </TotalLabel>
    </div>
  );
};

const MapStatsContainer = ({
  itemCount,
  eventCount,
  pieData,
  barData,
  loading,
}: {
  itemCount?: number;
  eventCount?: number;
  pieData?: any;
  barData?: any;
  loading?: boolean;
}) => {
  const maxItemCount = Math.pow(10, 6);
  return (
    <MapStatsWrapper className="map-stats-container">
      <MapStatsTitle>Totals</MapStatsTitle>
      <MapStatsTotals>
        <TotalLabel style={{ marginTop: "0px" }}>
          Total debris count:{" "}
          {itemCount < maxItemCount ? (
            <Total>
              {loading
                ? "Loading..."
                : itemCount
                ? numberWithCommas(itemCount)
                : 0}
            </Total>
          ) : (
            <Total>Too many items</Total>
          )}
        </TotalLabel>
        <TotalLabel>
          Total collection events:{" "}
          <Total>
            {loading
              ? "Loading..."
              : eventCount
              ? numberWithCommas(eventCount)
              : 0}
          </Total>
        </TotalLabel>
      </MapStatsTotals>
      {itemCount < maxItemCount ? (
        <MapStatsGraphs>
          <Charts>
            <Chart>
              <h2>Distribution by Category</h2>
              <ChartWrapper>
                {loading ? (
                  <div>Loading...</div>
                ) : pieData && pieData.length > 0 ? (
                  <PieChart data={pieData} />
                ) : (
                  <div>Select a list to show Distribution by Category</div>
                )}
              </ChartWrapper>
            </Chart>
            <Chart left={true}>
              <h2>Top Items</h2>
              <ChartWrapper>
                {loading ? (
                  <div>Loading...</div>
                ) : barData && barData.length > 0 ? (
                  <BarChart data={barData} />
                ) : (
                  <div>Select a list to show Top Items</div>
                )}
              </ChartWrapper>
            </Chart>
          </Charts>
        </MapStatsGraphs>
      ) : (
        <TooManyItems />
      )}
    </MapStatsWrapper>
  );
};

export default MapStatsContainer;
