import * as React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout/Layout";
import Seo from "../components/seo";
import DataPlatform from "../components/DataPlatform/DataPlatform";
import DataPlatformContextProvider from "../context/DataPlatform/DataPlatformContextProvider";

const DataPage = () => {
  return (
    <DataPlatformContextProvider>
      <Layout vertical={false}>
        <Helmet>
          <script src="https://kit.fontawesome.com/4673245c66.js"></script>
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/leaflet.draw/0.4.2/leaflet.draw.css"
          />
          <link rel="stylesheet" href="https://unpkg.com/leaflet@1.7.1/dist/leaflet.css" />
          <script src="https://cdnjs.cloudflare.com/ajax/libs/leaflet.draw/0.4.2/leaflet.draw.js"></script>
        </Helmet>
        <Seo title="Data Platform" />
        <DataPlatform />
      </Layout>
    </DataPlatformContextProvider>
  );
};

export default DataPage;
