import React, { useContext } from "react";
import Select from "../../Forms/Select";
import styled from "styled-components";
import { Checkbox } from "@material-ui/core";
import { colors } from "../../../styles/colors";
import { DataPlatformContext } from "../../../context/DataPlatform/DataPlatformContext";

const CheckboxGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px 10px;
`;

const CheckboxLabel = styled.div`
  width: 150px;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: ${`${colors.black}20`};
  }
`;

const CheckboxLabels = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const CheckboxWrapper = styled.div`
  cursor: pointer;
`;

const DebrisTrackerCheckbox = ({
  children,
  isChecked,
  update,
}: {
  children: any;
  isChecked: boolean;
  update: () => void;
}) => {
  return (
    <CheckboxWrapper onClick={update}>
      <Checkbox color="primary" checked={isChecked} />
      <span>{children}</span>
    </CheckboxWrapper>
  );
};

const CategoriesFilter = () => {
  const { filters, setFilters, loading } = useContext(DataPlatformContext);

  const selectAll = () => {
    const newCheckboxes = filters.checkboxes.map((item) => {
      return { ...item, value: true };
    });
    setFilters({ ...filters, checkboxes: newCheckboxes });
  };

  const unselectAll = () => {
    const newCheckboxes = filters.checkboxes.map((item) => {
      return { ...item, value: false };
    });
    setFilters({ ...filters, checkboxes: newCheckboxes });
  };

  return (
    <Select title={"Categories"} enabled={filters.list !== undefined}>
      <CheckboxLabels>
        <CheckboxLabel onClick={selectAll}>Select All</CheckboxLabel>
        <CheckboxLabel onClick={unselectAll}>Unselect All</CheckboxLabel>
      </CheckboxLabels>
      <CheckboxGrid>
        {!loading &&
          filters.checkboxes &&
          filters.checkboxes.map((checkbox, i) => {
            return (
              <DebrisTrackerCheckbox
                key={i}
                isChecked={checkbox.value}
                update={() => {
                  const idx = filters.checkboxes.findIndex(
                    (box) => box.id === checkbox.id
                  );
                  const newCheckboxes = filters.checkboxes.map((item, i) => {
                    if (i !== idx) {
                      return item;
                    } else {
                      return { ...item, value: !item.value };
                    }
                  });
                  setFilters({ ...filters, checkboxes: newCheckboxes });
                }}
              >
                {checkbox.name}
              </DebrisTrackerCheckbox>
            );
          })}
      </CheckboxGrid>
    </Select>
  );
};

export default CategoriesFilter;
