import React, { useContext } from "react";
import { DataPlatformContext } from "../../../context/DataPlatform/DataPlatformContext";
import { getOrganization } from "../../../dataApi/getOrganizations";
import Select from "../../Forms/Select";

const ListSelect = () => {
  const { lists, loading, filters, setFilters } = useContext(
    DataPlatformContext
  );

  // create list name for select to account for long names
  const createListName = (name: string) => {
    if (name.length > 30) {
      return name.substr(0, 30) + "...";
    } else {
      return name;
    }
  };

  const selectData: { label: string; value: string }[] = [
    {
      label: "All Organizations",
      value: "-1",
    },
  ];

  if (lists) {
    lists.forEach((list) => {
      selectData.push({ label: list.name, value: `${list.id}` });
    });
  }

  return (
    <Select
      onChange={async (val) => {
        if (val === "-1") {
          setFilters({ ...filters, list: "all" });
          return;
        }
        const listId = lists.find((list) => `${list.id}` === val).id;
        // fetch detailed list data
        const list = await getOrganization(listId);
        setFilters({ ...filters, list: list });
      }}
      data={selectData}
      title={
        !filters.list && loading
          ? "Loading..."
          : filters.list
          ? filters.list === "all"
            ? "All Organizations"
            : createListName(filters.list.name)
          : "Select an Organization"
      }
    ></Select>
  );
};

export default ListSelect;
