import { createContext } from "react";
import { FilterType } from "../../types/filters";
import { ListType } from "../../types/list";

type MapDataType = { latitude: string; longitude: string; [k: string]: any };

interface DataPlatformContextType {
  lists?: ListType[];
  data?: MapDataType[];
  setData?: (s: MapDataType[]) => void;
  loading?: boolean;
  err?: string;
  filters?: FilterType;

  setFilters?: (f: FilterType) => void;

  loadData?: (f: FilterType) => Promise<any[]>;
}

export const DataPlatformContext = createContext<DataPlatformContextType>({});
