import React, { useEffect, useState } from "react";
import { FilterType, CheckboxType } from "../../types/filters";
import { DataPlatformContext } from "./DataPlatformContext";
import dayjs from "dayjs";
import { ListType } from "../../types/list";
import { useItemData } from "../../util/useItemData";
import { useAllOrgData } from "../../util/hooks/useOrgData";
import useGlobalMaterials from "../../util/hooks/useGlobalMaterials";

// interface DataPlatformState {
//   orgs: ListType[];
//   filters: FilterType;
// }

// interface DataPlatformActions {
//   LOAD_ORGS: "LOAD_ORGS";
//   UPDATE_SELECTED_ORG: "UPDATE_SELECTED_ORG";
//   LOAD_POINTS: "LOAD_POINTS";
//   LOAD_STATS: "LOAD_STATS";
//   LOAD_DETAILS: "LOAD_DETAILS";
// }

const DataPlatformContextProvider = ({ children }: { children?: any }) => {
  const defaultFilters: FilterType = {
    checkboxes: [],
    date_type: "range",
    exact_date: dayjs().format("YYYY-MM-DD"),
    start_date: dayjs().subtract(1, "M").format("YYYY-MM-DD"),
    end_date: dayjs().format("YYYY-MM-DD"),
    list: undefined,
    user_data: -1,
    showManual: false,
  };

  //TODO: Reimplement filter state management as reducer
  // const filterReducer = (prev: FilterType, action: any) => {};

  const [filters, setFilters] = useState<FilterType>(defaultFilters);

  const [data, setData] = useState<any[]>([]);

  const { organizations, loading: orgsLoading } = useAllOrgData();
  const { loadData, loading: itemsLoading } = useItemData();
  const {
    allOrgSections,
    loading: globalMaterialsLoading,
  } = useGlobalMaterials();

  /*********************/
  /** Update handlers **/
  /*********************/

  // on list change, extract materials and create
  // material checkboxes
  useEffect(() => {
    if (filters.list !== "all") {
      const list: ListType = filters.list;
      // @ts-ignore
      if (list && list.materials) {
        // @ts-ignore
        list.sections = list.materials;
      }
      const newCheckboxes: CheckboxType[] =
        list &&
        list.sections &&
        list.sections.map((sections) => {
          return {
            id: `${sections.id}`,
            name: sections.description,
            value: true,
          };
        });
      setFilters({ ...filters, checkboxes: newCheckboxes });
    } else {
      //handle all organizations

      const newCheckboxes: CheckboxType[] =
        !globalMaterialsLoading &&
        allOrgSections &&
        allOrgSections.map((section) => {
          return {
            id: `${section.material_id}`,
            name: section.name,
            value: true,
          };
        });
      setFilters({ ...filters, checkboxes: newCheckboxes });
    }
  }, [filters.list]);

  const dataPlatformState = {
    lists: organizations,
    loading: orgsLoading || itemsLoading || globalMaterialsLoading,

    data,
    setData,

    filters,
    setFilters,

    loadData,
  };

  return (
    <DataPlatformContext.Provider value={dataPlatformState}>
      {children}
    </DataPlatformContext.Provider>
  );
};

export default DataPlatformContextProvider;
