import React, { useContext, useEffect, useRef, useState } from "react";
import Filters from "./Filters";
import ItemsMap from "./ItemsMap";
import MapStats from "./MapStats";
import styled from "styled-components";
import { DataPlatformContext } from "../../context/DataPlatform/DataPlatformContext";
import { convertToGeoJSON } from "../../util/data/convertToGeoJSON";
import { applyFilters } from "../../util/data/itemsDataFunctions";
import pointsWithinPolygon from "@turf/points-within-polygon";

const DataPlatformContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;

const ControlsContainer = styled.div`
  height: 100%;
  display: flex;
  @media only screen and (max-width: 991px) {
  }

  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }

  @media only screen and (max-width: 478px) {
  }
`;

const DataPlatform = () => {
  const { filters, data, loading } = useContext(DataPlatformContext);

  // drawn polygon
  const [filterPolygon, setFilterPolygon] = useState<L.Polygon>(undefined);

  //copy of the raw geojson data. used when data needs to be filtered without fetching new data.
  const defaultGeojson: GeoJSON.FeatureCollection = {
    type: "FeatureCollection",
    features: [],
  };

  const unfilteredGeojson = useRef<GeoJSON.FeatureCollection>(defaultGeojson);

  const [geojson, setGeojson] = useState<GeoJSON.FeatureCollection>(
    unfilteredGeojson.current
  );

  // update data
  useEffect(() => {
    if (data && data.length > 0) {
      let geojson = convertToGeoJSON(data);
      unfilteredGeojson.current = geojson;
      let filteredGeojson = geojson;

      if (filters.date_type !== "all" && filters.list != "all") {
        geojson = applyFilters(filters, geojson);
        unfilteredGeojson.current = geojson;
        filteredGeojson = applyFilters(filters, unfilteredGeojson.current);
      }

      setGeojson(filteredGeojson);
    } else if (data && data.length === 0) {
      setGeojson(defaultGeojson);
    }
  }, [data]);

  // update on filter change
  useEffect(() => {
    if (data && data.length > 0) {
      setGeojson(applyFilters(filters, unfilteredGeojson.current));
    }
  }, [filters]);

  // update data on polygon change
  useEffect(() => {
    if (data && data.length > 0) {
      if (filterPolygon === undefined) {
        setGeojson(applyFilters(filters, unfilteredGeojson.current));
      } else {
        const points = pointsWithinPolygon(
          // @ts-ignore
          geojson,
          filterPolygon.toGeoJSON()
        );
        setGeojson(points);
      }
    }
  }, [filterPolygon]);

  return (
    <DataPlatformContainer>
      {typeof window !== "undefined" && (
        <ItemsMap
          cluster={true}
          loading={loading}
          geojson={geojson}
          filters={filters}
          interactive={true}
          setFilterPolygon={setFilterPolygon}
          draw={true}
        />
      )}
      <ControlsContainer>
        <Filters geojson={geojson} />
        <MapStats loading={loading} geojson={geojson} />
      </ControlsContainer>
    </DataPlatformContainer>
  );
};

export default DataPlatform;
